import React from 'react'
import {timeConvert, timeConvertSec} from './../helperfunctions'

class Fltplan extends React.Component {

    fpTopInfoBox(routedesc, wpList) {
        let toc =  wpList.WayPoint.find(function(element){
            return element.sFunction[0].includes("TopOfClimb")
        })
        let tod =  wpList.WayPoint.find(function(element){
            return element.sFunction[0].includes("TopOfDescent")
        })
        let tocTime = parseFloat(toc.dbEstCumulatedFlightTime_H[0])
        let todTime = parseFloat(tod.dbEstCumulatedFlightTime_H[0])

        const wpWithlowtemp = wpList.WayPoint.find(function(element) {
            let temp = parseInt(element.nSegmentTemperature_C[0])
            let time = parseFloat(element.dbEstCumulatedFlightTime_H[0])
            return temp <= -50 && temp > -99999999 && time > tocTime && time < todTime
        })
        let wpWithlowtempName = ""
        if(wpWithlowtemp) {
            wpWithlowtempName = <p className="temp-caution-text">
                Caution: Temperature at or below -70 at {wpWithlowtemp.sWaypointId[0]}
            </p>
        }

        return (
            <div className="fp-top-info-box">
                <p className="top-route-desc">{routedesc}</p>
                {wpWithlowtempName}
            </div>
        )
    }

    allTitles() {
        return (
            <div className="fp-title-header">
                <p className="fp-title-IDENT">IDENT</p>
                <p className="fp-title-T">T</p>
                <p className="fp-title-AWY">AWY</p>
                <p className="fp-title-GMORA">GMORA</p>
                <p className="fp-title-MT">MT</p>
                <p className="fp-title-TAS">TAS</p>
                <p className="fp-title-DIST">DIST</p>
                <p className="fp-title-TIME">TIME</p>
                <p className="fp-title-ETO">ETO</p>
                <p className="fp-title-RET">RET</p>
                <p className="fp-title-ATO green-grey-title">ATO</p>
                <p className="fp-title-USED">USED</p>
                <p className="fp-title-ACT green-grey-title">ACT</p>
                <p className="fp-title-DIFF1">DIFF</p>
                <p className="fp-title-FL">FL</p>
                <p className="fp-title-ACT-FL">ACT FL</p>
                <p className="fp-title-WV">W/V</p>
                <p className="fp-title-RAD-HDG green-grey-title">RAD HDG</p>
                <p className="fp-title-TT">TT</p>
                <p className="fp-title-GS">GS</p>
                <p className="fp-title-REM">REM</p>
                <p className="fp-title-ACC">ACC</p>
                <p className="fp-title-MREQ">MREQ</p>
                <p className="fp-title-DIFF2">DIFF</p>
                <p className="fp-title-DIFF3">DIFF</p>
                <p className="fp-title-LAT">LAT</p>
                <p className="fp-title-LON">LON</p>
                <p className="fp-title-FREQ">FREQ</p>
                <p className="fp-title-ATC-FREQ green-grey-title">ATC FREQ</p>
                <p className="fp-title-ISA">ISA</p>
                <p className="fp-title-OAT">OAT</p>
                <p className="fp-title-REM2">REM</p>
                <p className="fp-title-FREM">FREM</p>
                <p className="fp-title-ONB green-grey-title">ONB</p>
                <p className="mobile-device-line1"></p><p className="mobile-device-line1"></p><p className="mobile-device-line1"></p><p className="mobile-device-line1"></p><p className="mobile-device-line1"></p>
                <p className="mobile-device-line2"></p><p className="mobile-device-line2"></p><p className="mobile-device-line2"></p><p className="mobile-device-line2"></p><p className="mobile-device-line2"></p>

            </div>
        )

    }

    oneAltRouteRow(data,key, isTopRow) {
        let awy = data.sAirway[0].split(" ")
        awy = awy[awy.length-1]
        let gmora = parseInt(data.nMinimumSafeAltitude_FT)/100
        if(gmora < 9999999){
            gmora = ""
        }
        let wv = String(data.sWindDir[0].replace(/\D/g,'')) + '/' + String(data.sWindSpeed[0].replace(/\D/g,''))
        let lat = (parseFloat(data.nLat[0].replace(/\D/g,''))/3600).toFixed(4)
        let lon = (parseFloat(data.nLon[0].replace(/\D/g,''))/3600).toFixed(4)
        let latText = data.nLat[0]
        let lonText = data.nLon[0]
        let latpreword
        let lonpreword
        if( latText.includes("-")){
             latpreword = "S"
        }else{
             latpreword = "N"
        }
        if( lonText.includes("-")){
             lonpreword = "W"
        }else{
             lonpreword = "E"
        }

        lat = timeConvertSec(lat)
        lon = timeConvertSec(lon)
        let time = parseInt(parseFloat(data.dbEstTimeFromPreviousWaypoint_H[0]*60))
        if(time < -9999999){
            time = ""
        }
        let acc = data.dbEstCumulatedFlightTime_H[0]
        let rem = data.dbEstRemainingFlightTime_H[0]
        acc = timeConvert(acc)
        rem = timeConvert(rem)

        let isa = parseInt(data.dbSegmentISADeviation_C[0])
        if(isa < -9999999){
            isa = ""
        }
        let oat = parseInt(data.nSegmentTemperature_C[0])
        if(oat < -9999999){
            oat = ""
        }
        let gs = parseInt(data.nEstGS_KT[0])
        if(gs < -9999999){
            gs = ""
        }
        let tas = parseInt(data.nEstTAS_KT[0])
        if(tas < -9999999){
            tas = ""
        }
        let dist = parseInt(data.nGroundDistance_NM[0])
        if(dist < -9999999){
            dist = ""
        }


        let divClassName = "fp-title-header"
        if(isTopRow){
            divClassName = "fp-title-header top-fp-row"
        }


        return(
        <div key={key} className={divClassName}>

            <p className="fp-title-IDENT">{data.sWaypointId}</p>
            <p className="fp-title-T hidden-in-real-fp">T</p>
            <p className="fp-title-AWY">{awy}</p>
            <p className="fp-title-GMORA">{gmora}</p>
            <p className="fp-title-FL hidden-in-real-fp">FL</p>
            <p className="fp-title-ACT-FL hidden-green hidden-in-real-fp">ACT FL</p>
            <p className="fp-title-WV">{wv}</p>
            <p className="fp-title-RAD-HDG hidden-green hidden-in-real-fp">RAD HDG</p>
            <p className="fp-title-LAT">{latpreword}{lat}</p>
            <p className="fp-title-LON">{lonpreword}{lon}</p>
            <p className="fp-title-FREQ hidden-in-real-fp">FREQ</p>

            <p className="mobile-device-line1"></p><p className="mobile-device-line1"></p><p className="mobile-device-line1"></p><p className="mobile-device-line1"></p><p className="mobile-device-line1"></p>

            <p className="fp-title-MT">{data.nSegmentTrack_Mag_Deg}</p>
            <p className="fp-title-TAS">{tas}</p>
            <p className="fp-title-DIST">{dist}</p>
            <p className="fp-title-TIME">{time}</p>
            <p className="fp-title-ETO hidden-in-real-fp">ETO</p>
            <p className="fp-title-TT">{data.nSegmentTrack_True_Deg}</p>
            <p className="fp-title-GS">{gs}</p>
            <p className="fp-title-REM">{data.nRemainingGroundDistance_NM}</p>
            <p className="fp-title-ACC">{acc}</p>
            <p className="fp-title-ATC-FREQ hidden-green hidden-in-real-fp">ATC FREQ</p>
            <p className="fp-title-ISA">{isa}</p>
            <p className="fp-title-OAT">{oat}</p>
            <p className="fp-title-REM2">{rem}</p>

        <p className="mobile-device-line2"></p><p className="mobile-device-line2"></p><p className="mobile-device-line2"></p><p className="mobile-device-line2"></p><p className="mobile-device-line2"></p>

            <p className="fp-title-RET hidden-in-real-fp">RET</p>
            <p className="fp-title-ATO hidden-green hidden-in-real-fp">ATO</p>
            <p className="fp-title-USED">{data.nEstCumulatedBurnOff_KG}</p>
            <p className="fp-title-ACT hidden-green hidden-in-real-fp">ACT</p>
            <p className="fp-title-DIFF1 hidden-in-real-fp">DIFF</p>
            <p className="fp-title-MREQ">{data.nMinimuFuelOnBoard_KG}</p>
            <p className="fp-title-DIFF2 hidden-in-real-fp">DIFF</p>
            <p className="fp-title-FREM">{data.nEstFuelOnBoard_KG}</p>
            <p className="fp-title-ONB hidden-green hidden-in-real-fp">ONB</p>
            <p className="fp-title-DIFF3 hidden-in-real-fp">DIFF</p>
        </div>
    )
    }
    topAltRow(data){
        let awy = data.sAirway[0].split(" ")
        awy = awy[awy.length-1]
        let gmora = parseInt(data.nMinimumSafeAltitude_FT)/100
        let wv = String(data.sWindDir[0].replace(/\D/g,'')) + '/' + String(data.sWindSpeed[0].replace(/\D/g,''))
        let lat = (parseFloat(data.nLat[0])/3600).toFixed(4)
        let lon = (parseFloat(data.nLon[0])/3600).toFixed(4)
        let time = parseInt(parseFloat(data.dbEstTimeFromPreviousWaypoint_H[0]*60))
        let acc = data.dbEstCumulatedFlightTime_H[0]
        let rem = data.dbEstRemainingFlightTime_H[0]
        acc = timeConvert(acc)
        rem = timeConvert(rem)

        return(
        <div  className="fp-title-header top-row">
            <p className="fp-title-IDENT">{data.sWaypointId}</p>
            <p className="fp-title-T hidden-in-real-fp">T</p>
            <p className="fp-title-AWY">{awy}</p>
            <p className="fp-title-GMORA">{gmora}</p>
            <p className="fp-title-MT"></p>
            <p className="fp-title-TAS"></p>
            <p className="fp-title-DIST"></p>
            <p className="fp-title-TIME">{time}</p>
            <p className="fp-title-ETO hidden-in-real-fp">ETO</p>
            <p className="fp-title-RET hidden-in-real-fp">RET</p>
            <p className="fp-title-ATO hidden-green hidden-in-real-fp">ATO</p>
            <p className="fp-title-USED">{data.nEstCumulatedBurnOff_KG}</p>
            <p className="fp-title-ACT hidden-green hidden-in-real-fp">ACT</p>
            <p className="fp-title-DIFF1 hidden-in-real-fp">DIFF</p>
            <p className="fp-title-FL hidden-in-real-fp">FL</p>
            <p className="fp-title-ACT-FL hidden-green hidden-in-real-fp">ACT FL</p>
            <p className="fp-title-WV">{wv}</p>
            <p className="fp-title-RAD-HDG hidden-green hidden-in-real-fp">RAD HDG</p>
            <p className="fp-title-TT"></p>
            <p className="fp-title-GS"></p>
            <p className="fp-title-REM">{data.nRemainingGroundDistance_NM}</p>
            <p className="fp-title-ACC">{acc}</p>
            <p className="fp-title-MREQ">{data.nMinimuFuelOnBoard_KG}</p>
            <p className="fp-title-DIFF2 hidden-in-real-fp">DIFF</p>
            <p className="fp-title-LAT">{lat}</p>
            <p className="fp-title-LON">{lon}</p>
            <p className="fp-title-FREQ hidden-in-real-fp">FREQ</p>
            <p className="fp-title-ATC-FREQ hidden-green hidden-in-real-fp">ATC FREQ</p>
            <p className="fp-title-ISA"></p>
            <p className="fp-title-OAT">{data.nSegmentTemperature_C}</p>
            <p className="fp-title-REM2">{rem}</p>
            <p className="fp-title-FREM">{data.nEstFuelOnBoard_KG}</p>
            <p className="fp-title-ONB hidden-green hidden-in-real-fp">ONB</p>
            <p className="fp-title-DIFF3 hidden-in-real-fp">DIFF</p>
        </div>
    )

    }

    render() {
        let headerContent = this.allTitles()
        let topInfoBox = this.fpTopInfoBox(this.props.data.sRouteDescription[0], this.props.data.WP_List[0])
        let allAltRoutes = []

        for( let i=0 ; i < this.props.data.WP_List[0].WayPoint.length; i ++){
            let key = "fp-altroute"+i
            let toprow = false;
            if(i===0){
                toprow = true
            }
            allAltRoutes.push(this.oneAltRouteRow(this.props.data.WP_List[0].WayPoint[i],key,toprow))
        }
        for( let k = 0 ; k < this.props.data.AltRoute_List[0].AlternateRoute[0].AltWP_List[0].WayPoint.length ; k++){
            let key = "fp-alt1route"+k
            let toprow = false;
            if(k===0){
                toprow = true
            }
            allAltRoutes.push(this.oneAltRouteRow(this.props.data.AltRoute_List[0].AlternateRoute[0].AltWP_List[0].WayPoint[k],key,toprow))
        }



        return (
            <div className="flt-plan-container">
                <p className="visible-when-small-screen">For better readability, use horizontal mode!</p>
                {topInfoBox}
                {headerContent}
                <div className="fp-all-fps"></div>
                {allAltRoutes}
            </div>
        )
    }
}

export default Fltplan;
