import React from 'react'

import './../style/flightnavbar.scss'

class FlightNavbar extends React.Component {
    constructor(){
        super()

    }

    handleClick(tabnumber){

        this.props.changeTab(tabnumber)
    }

    createSlotRow(){
        let slots = []
        const names = ["FLIGHT DATA", "FLT PLAN","WX T", "WX G", "NOTAM", "CREW MSG", "MAINT", "RAIM"]
        let iteratorcounter = 0
        for(let i = 0; i < names.length; i++){

            if(this.props.activeSlot == i){
                slots.push(
                    <div className="navbar-slot slot-active" value={iteratorcounter} key={iteratorcounter} >
                        <h2>
                            {names[i]}
                        </h2>
                    </div>)
            }else {

                slots.push(
                    <div className="navbar-slot" value={iteratorcounter} key={iteratorcounter} onClick= {() => this.handleClick(i)}>
                        <h2>
                            {names[i]}
                        </h2>
                    </div>)
            }
            iteratorcounter = iteratorcounter + 1

        }
        return slots
    }
    render () {
        let navbar = this.createSlotRow()
        return(
            <div>
                <div className="navbar-container">
                    {navbar}
                </div>
            </div>
        )
    }
}

export default FlightNavbar;
