import React from 'react'


class Raim extends React.Component {

    render () {

        return(
            <div className="raim-container">
                <div className="raim-content">
                    <pre>
                        {this.props.data[0]}
                    </pre>
                </div>
            </div>
        )
    }
}

export default Raim;
