import React from 'react'


class Maint extends React.Component {

    oneDestWeather(data,key){

        return(
            <div key={key} className="one-w-dest">
                <pre>
                    {data}
                </pre>
            </div>
        )
    }
    render () {
        let maint
        if(this.props.data.sABC_MEL){
            maint = this.props.data.sABC_MEL[0]
        }

        return(
            <div className="wxt-container">

                <div className="maint-text">

                    <pre>
                        {maint}
                    </pre>

                </div>
            </div>
        )
    }
}

export default Maint;
