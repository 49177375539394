import React, {Component} from 'react';
import equal from 'fast-deep-equal'
import Switch from "react-switch";


import FlightNavbar from './components/FlightNavbar'
import ContentWindow from './components/ContentWindow'
import SelectFP from './components/SelectFP'
import Topinfoline from './components/Topinfoline'

import Login from './pages/Login'
import Logout from './pages/Logout'
import Cabincrewpage from './pages/Cabincrewpage'
import {getFltsCmd, getFltXmlCmd} from './helperfunctions'
import { set, get } from 'idb-keyval';

// Import pages
import Notam from './pages/Notam'
import WXT from './pages/WXT'
import Fltdata from './pages/Fltdata'
import WXG from './pages/WXG'
import CrewMSG from './pages/CrewMSG'
import Fltplan from './pages/Fltplan'
import Fltplannew from './pages/Fltplannew'
import Maint from './pages/Maint'
import Raim from './pages/Raim'

const soapRequest = require('easy-soap-request');
const parseString = require('xml2js').parseString;



class Mainapp extends Component {
    constructor(){
        super()
            this.state = {
                allData: { data: null },
                allFlights: { data: null },
                activeTab: 0,
                isLoaded: false,
                foundData: false,
                status: "Loading ...",
                flightSelected: 0,
                isCabinCrew: false,
            }
            this.getFltXml = this.getFltXml.bind(this)
            this.changeActiveTab = this.changeActiveTab.bind(this)
            this.changeFlight = this.changeFlight.bind(this)
            this.filterFlights = this.filterFlights.bind(this)
            this.callXml = this.callXml.bind(this)
            this.cabinCaptainChange = this.cabinCaptainChange.bind(this)
            this.fetchflights = this.fetchflights.bind(this)
            this.getCabinCrewLocal = this.getCabinCrewLocal.bind(this)
    }

    /**
    * Fetchflights is called in componentwillmount and propswillupdate.
    *  It takes the empo and makes sure it i authorized on the page
    * it tries to access and also calss getFlts to fetch flights from webservice
    */

    fetchflights(){
        if(navigator.onLine){
            let empnoData = {empno: this.props.empnumber};


            // TODO change url for deployment
            fetch('https://lbserver.norwegian.com/checksameuser', {
                    credentials: 'include',
                    method: 'POST',
                    body: JSON.stringify(empnoData),
                    headers:{
                        'Content-Type': 'application/json'
                  }
                }).then(res => res.json())
                .then(response => {
                    if(response[0].authBool == true){
                        this.getFlts()
                    }else{
                        this.setState({status: "Not authorized"})
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                    this.setState({status: "Server fetch error"})
                });

        } else {
            let dbname = "flight" + this.state.flightSelected
            get(dbname).then( async(val) =>{

                await  this.setState({allData: val})
                this.setState({isLoaded: true})
            }).catch(err => console.log('IndexedDb failed!', err));
            get("allFlights").then( async(val) =>{

                await  this.setState({allFlights: val})

            }).catch(err => console.log('IndexedDb failed!', err));

        }
    }

    /**
    * This function fetches all the flights belonging to the give empno
    * It fetches the flights through a soapRequest to the webservice.
    * It then calls the filterFlights function to filter out flights that are to old
    */
    getFlts(){
        // TODO change url for deployment
        const url = 'https://lbservice.norwegian.com/FindCrew.asmx';
        const headers = {
            'Content-Type': 'text/xml;charset=UTF-8',
            SOAPAction: 'http://flightdecksoftware.se/getFlts',

        };
        // Takes empno from props to fetch flighst belonging to that emno
        // This function can be found in helperfunctions
        const xml = getFltsCmd(this.props.empnumber)
        const makeRequest = async() => {
            try{
                const {response} = await soapRequest(url, headers, xml, 10000); // Optional timeout parameter(milliseconds)
                const {body, statusCode} = response;

                parseString(body, function (err, result) {
                    //console.log(result) take away this comment to log result from get flts
                    if(result["soap:Envelope"]["soap:Body"]["0"].getFltsResponse["0"].getFltsResult["0"] !== ""){
                        const data = result["soap:Envelope"]["soap:Body"]["0"].getFltsResponse["0"].getFltsResult["0"];
                            this.filterFlights(data,this.callXml)

                }else {
                    this.setState({status: "No flights found"})

                }

                }.bind(this));
            }catch(err){
                console.log(err)
                console.log("Fetch error")
                this.setState({status: "Could not fetch data due to server error. Try reloading the app"})
            }
            };
            makeRequest();


    }

    /*
    * Filter outs flights that are to old or to now
    * the callXml function is used as callback
    **/

    filterFlights(flights, callXmlAsCallback){
        let filterdFlightArray = []
        for(let i =0; i < flights.flt.length; i++){
            let flightdateAndTimeString = flights.flt[i].sSTD[0]
            let flightDateString = flightdateAndTimeString.split(" ")[0]
            let flightDate = new Date(flightDateString)
            let currDate = new Date()

            currDate.setHours(0,0,0,0)

            // Below are the limits on what days to fetch set.
            // current setting is between 9 days back and 2 days forward
            let lastDayTofetch = this.addDays(currDate, 2)
            let firstDayToFetch = this.addDays(currDate, -9)


            if(flightDate >= firstDayToFetch && flightDate <= lastDayTofetch){

                filterdFlightArray.push(flights.flt[i])
            }


        }
        this.setState({allFlights: filterdFlightArray})

        set("allFlights", filterdFlightArray)
        .catch(err => console.log('It failed!', err));

        callXmlAsCallback()
    }

    callXml(){
        // checking if there are available flights
        if(this.state.allFlights[0] !== undefined){
            // This fetches the flight to be shown in the app
            this.getFltXml(this.state.flightSelected)
            for( let i = 0 ; i < this.state.allFlights.length; i++){
                // This function saves all the flights to indexdb
                this.saveXmltoIndexDB(i)
            }
        } else {
            this.setState({status: "No flights found"})
            this.setState({isLoaded: false})
        }
    }

    /*
    * Here the soap request to the web service is sent that returns the info about a
    * certain flight in an xml file
    *
    **/

    getFltXml(i){
        // TODO change url for deployment
        const url = 'https://lbservice.norwegian.com/FindCrew.asmx';
        const headers = {
            'Content-Type': 'text/xml;charset=UTF-8',
            SOAPAction: 'http://flightdecksoftware.se/getFlt_xml',

        };
        // Setting the input to the soap request
        // This function can be found in helperfunctions
        const xml = getFltXmlCmd(this.state.allFlights[i].sFltNo[0],
                                this.state.allFlights[i].sDep[0],
                                this.state.allFlights[i].sDest[0],
                                this.state.allFlights[i].sSTD[0].split(" ")[0],
                                this.state.allFlights[i].sACReg[0])

        const makeRequest = async () => {
            try{
                const {response} = await soapRequest(url, headers, xml, 10000); // Optional timeout parameter(milliseconds)
                const {body, statusCode} = response;


                parseString(body, function (err, result) {
                    parseString(result["soap:Envelope"]["soap:Body"]["0"].getFlt_xmlResponse["0"].getFlt_xmlResult["0"], function (err, result2) {
                        var data = result2

                        if(data !== null){

                            this.setState({allData: data.FlightPlanObj})

                            let indexedDbName = "flight"+i
                            set(indexedDbName, data.FlightPlanObj).then(() => {
                                    // console.log(data.FlightPlanObj) take away this comment to log result from get fltsxml
                                    this.setState({isLoaded: true})
                                })
                        } else {
                            this.setState({status: "No data found"})
                        }
                    }.bind(this));


                }.bind(this));
            }catch(err){
                console.log(err)
                console.log("Fetch error")
                this.setState({status: "Could not fetch data due to server error. Try reloading the app"})
            }

            };
            makeRequest();
    }

    /*
    *Function to save all the flights in indexedDb soit can be used offline
    *
    **/
    saveXmltoIndexDB(i){
        // TODO change url for deployment
        const url = 'https://lbservice.norwegian.com/FindCrew.asmx';
        const headers = {
            'Content-Type': 'text/xml;charset=UTF-8',
            SOAPAction: 'http://flightdecksoftware.se/getFlt_xml',

        };
        // This function can be found in helperfunctions
        const xml = getFltXmlCmd(this.state.allFlights[i].sFltNo[0],
                                this.state.allFlights[i].sDep[0],
                                this.state.allFlights[i].sDest[0],
                                this.state.allFlights[i].sSTD[0].split(" ")[0],
                                this.state.allFlights[i].sACReg[0])

        const makeRequest = async () => {
            const {response} = await soapRequest(url, headers, xml, 5000); // Optional timeout parameter(milliseconds)
            const {body, statusCode} = response;


            parseString(body, function (err, result) {
                    parseString(result["soap:Envelope"]["soap:Body"]["0"].getFlt_xmlResponse["0"].getFlt_xmlResult["0"], function (err, result2) {
                        var data = result2
                        if(data !== null){

                            let indexedDbName = "flight"+i
                            set(indexedDbName, data.FlightPlanObj).then(() => {
                                }).catch(err => console.log('It failed!', err));

                        }
                    }.bind(this));


                }.bind(this));

                //console.log(statusCode);
            };
            makeRequest();
    }

    /*
    * Small function to add or subtract days to a date object
    * Used to set limits of days in wich flights should be fetched from.
    **/

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }


    changeActiveTab(i){
        if(this.state.isLoaded){
            this.setState({activeTab: i})
        }

    }

    /*
    * Function to change the flight that is shown
    **/

    changeFlight(i){
        if(navigator.onLine){
            this.setState({flightSelected:i})
            this.getFltXml(i)
        } else {
            this.setState({flightSelected:i})
            let flightName = "flight"+i
            get(flightName).then( async(val) =>{
                    await  this.setState({allData: val})
                }).catch(err => console.log('IndexedDb failed!', err));
        }
    }



    componentDidUpdate(prevProps) {
    if(!equal(this.props.empnumber, prevProps.empnumber))
    {
            this.setState({
                            isLoaded: false,
                            foundData: false,
                        })
           this.fetchflights();
    }
}


    componentWillMount() {
        this.fetchflights()
        let isCabinCrew = this.getCabinCrewLocal()
        this.setState({isCabinCrew: isCabinCrew})
    }

    // Function that triggers to change from cabin crew view or the ohter way around
    cabinCaptainChange(){
        let isCabinCrew = !this.state.isCabinCrew
        this.setState({isCabinCrew})
        localStorage.setItem('isCabinCrew', JSON.stringify(isCabinCrew) )
    }

    getCabinCrewLocal(){
        var value = localStorage.getItem('isCabinCrew') || false
        if(value === false){
            this.setState({isCabinCrew: value})
        }else{
            value = JSON.parse(value)
            this.setState({isCabinCrew: JSON.parse(value)})
        }

        return (value)
    }


    /*
    * Code for the button up top that you can swtich between cpt and cabin view
    **/
    crewtypeButton(){
        return(
            <div>
                <label>
                    <Switch
                        onChange={this.cabinCaptainChange}
                        checked={this.state.isCabinCrew}
                        onColor="#858585"
                         offColor="#858585"
                         onHandleColor="#ffffff"
                         offHandleColor="#ffffff"
                         handleDiameter={35}
                         uncheckedIcon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 13,
                                color: "white",
                                paddingRight: 50,
                                fontWeight: "bold"
                              }}
                            >
                              FlightDeck
                            </div>
                          }
                         checkedIcon= {
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 15,
                                    color: "white",
                                    paddingLeft: 20,
                                   fontWeight: "bold"
                                  }}
                                >
                                  Cabin
                                </div>
                              }
                         boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                         activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                         height={30}
                         width={120}
                         className="react-switch"
                         id="material-switch"
                         />
                </label>
              </div>
        )
    }

    render() {

        const isLoaded = this.state.isLoaded
        let content
        let status = this.state.status
        let navbar
        let buttontext

        if(this.state.isCabinCrew == true){
            content = <Cabincrewpage data = {this.state.allData}/>
            buttontext = "change to cpt-page"
        } else {
            buttontext = "change to cabincrew-page"
            navbar = <FlightNavbar activeSlot= {this.state.activeTab} changeTab={this.changeActiveTab}/>
            if(this.state.activeTab === 0){
                content = <Fltdata data={this.state.allData} />
            } else if(this.state.activeTab === 1){
               content = <Fltplannew data= {this.state.allData.sOFP1}/>
            } else if( this.state.activeTab === 2){
                content = <WXT data= {this.state.allData.Weather_List[0]}/>
            } else if( this.state.activeTab === 3){
                content = <WXG data= {this.state.allData.Image_List[0]}/>
            } else if(this.state.activeTab === 4){
                content = <Notam data= {this.state.allData.NOTAM_List}/>
            }else if( this.state.activeTab === 5){
                content = <CrewMSG data={this.state.allData}/>
            }else if( this.state.activeTab === 6){
                content = <Maint data={this.state.allData}/>
            }else if( this.state.activeTab === 7){
                content = <Raim data={this.state.allData.sRAIM}/>
            }else {
                content = <ContentWindow data = {this.state.allData}/>
            }
        }

        let changeCrewTypeButton = this.crewtypeButton()

        return (
            isLoaded ?
            <div className="App">
                {changeCrewTypeButton}
                <div className="main-page-header">
                    <SelectFP data={this.state.allFlights} handleClick={this.changeFlight}/>
                    <Logout/>
                </div>
                <Topinfoline data={this.state.allData}/>
                {navbar}
                {content}
            </div>
            :
            <div className="App">
                {changeCrewTypeButton}
                <div className="main-page-header">
                    <SelectFP data={this.state.allFlights} handleClick={this.changeFlight}/>
                    <Logout/>
                </div>
                <FlightNavbar activeSlot= {this.state.activeTab} changeTab={this.changeActiveTab}/>
                <h1 className="status-title"> {status}</h1>
            </div>
        );
    }
}

export default Mainapp;
