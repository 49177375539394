import React from 'react';
import axios from 'axios';
import norweiganLogo from './../static/RGB-Norwegian-logo.png';
import fdLogo from './../static/FDS-logo.png';
import Switch from "react-switch";
var ls = require('local-storage');


class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      loginResponse: '',
      isCabinCrew: ''
    };
    this.login = this.login.bind(this);
    this.cabinCaptainChange = this.cabinCaptainChange.bind(this)
    this.getCabinCrewLocal = this.getCabinCrewLocal.bind(this)
  }

  login() {
      // TODO change url for deployment
    window.location.href = 'https://lbserver.norwegian.com/api/login';

  }

  checkAuth() {
    fetch('/test1')
      .then(res => res.text())
      .then(res => alert(res));
  }
  isAuth() {
    fetch('/isAuth')
      .then(res => res.json())
      .then(responseData => {
        return responseData[0].authBool;
      });
  }

  cabinCaptainChange(){
      let isCabinCrew = !this.state.isCabinCrew
      this.setState({isCabinCrew})
      localStorage.setItem('isCabinCrew', JSON.stringify(isCabinCrew) )
  }

  getCabinCrewLocal(){
      var value = localStorage.getItem('isCabinCrew') || false
      if(value === false){
          this.setState({isCabinCrew: value})
      }else{
          value = JSON.parse(value)
          this.setState({isCabinCrew: JSON.parse(value)})
      }

      return (value)
  }

  componentWillMount(){
      this.getCabinCrewLocal()
  }


  render() {

      let button
      if(navigator.onLine){
          button =  <button className='login-btn' onClick={() => this.login()}>
             <p>Login</p>
           </button>;
      }else{
          button = <h1> You´re offline, log in not available</h1>
      }
    return (
      <div className='login-container'>
        <img className='norwegian-logo' src={norweiganLogo} />
        <div className='login-box'>
          <h1>Welcome to Norwegians Lido Briefing app!</h1>
          {button}
          <div>
              <p>Choose crew type</p>
          </div>
          <div>
              <label>
                  <Switch
                      onChange={this.cabinCaptainChange}
                      checked={this.state.isCabinCrew}
                      onColor="#ffffff"
                       offColor="#3A506B"
                       onHandleColor="#3A506B"
                       offHandleColor="#ffffff"
                       handleDiameter={35}
                       uncheckedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 13,
                              color: "white",
                              paddingRight: 50,
                              fontWeight: "bold"
                            }}
                          >
                            FlightDeck
                          </div>
                        }
                       checkedIcon= {
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "right",
                                  alignItems: "center",
                                  height: "100%",
                                  fontSize: 15,
                                  color: "#3A506B",
                                  paddingLeft: 20,
                                 fontWeight: "bold"
                                }}
                              >
                                Cabin
                              </div>
                            }
                       boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                       activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                       height={30}
                       width={120}
                       className="react-switch"
                       id="material-switch"
                       />
              </label>
            </div>
        </div>
        <img className="flightdecksoftware-logo" src={fdLogo}/>
        <p className="flightdeck-text">FligthDeck Software</p>
        <p className='info-text-email'>
          Having trouble logging in, send an e-mail to{' '}
          <a href={'mailto:' + 'efbfeedback@norwegian.com'}>
            efbfeedback@norwegian.com
          </a>
        </p>
        <p className='info-text-note'>
          Note: This app is not part of EFB operations and by no means
          considered a critical part of any system. Support on this product is
          very limited and not prioritized.
        </p>
      </div>
    );
  }
}

export default Login;
