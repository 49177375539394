import React from 'react'


class DispatcherRemark extends React.Component {
    render () {
        let dispRemark = this.props.data.sRemark[0]
        dispRemark = dispRemark.replace(/[|]/g, "\n")
        let dispInfo = this.props.data.sDipatcherInfo[0]
        dispInfo = dispInfo.replace(/[|]/g, "\n")

        return(
            <div className="dispatcher-remark-container">
                <pre>{dispRemark}</pre>
                <pre>{dispInfo}</pre>
            </div>
        )

    }
}

export default DispatcherRemark;
