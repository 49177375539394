import React from 'react'

//data:image/png;base64
class WXG extends React.Component {
    render() {
        let images = []
        let iteratorcounter = 0

        if (this.props.data !== undefined) {

            for (let i = 0; i < this.props.data.Image.length; i++) {
                let imageSrc = 'data:image/png;base64,' + this.props.data.Image[i].ImageContent[0]
                images.push(<img src={imageSrc}/>)
                iteratorcounter += 1
            }

        }
        return (
            <div className="weather-images">
                {images}
            </div>
        )

    }
}

export default WXG;
