import React from 'react'
import {timeConvert} from './../../helperfunctions'

class alternateRouteInfo extends React.Component {
    alternateRouteInfo(data){

        let altRouteinf = []
        const primArrival = data.Alt_List[0].Alternate.find(function(element){
            return element.sADFunction =="PrimaryArrivalAlternateAirport"
        })
        let primArrivalAltFueltime = parseFloat(primArrival.dbAlternateFuelDur_H[0])
        let primArrivalAltFuelWeight = parseInt(primArrival.nAlternateFuel_KG[0])
        let nBlockFuel_KG = parseInt(data.nBlockFuel_KG[0])
        for(let i = 0; i < data.AltRoute_List[0].AlternateRoute.length; i++){
            altRouteinf.push(
                this.alternateRouteInfoOneLine(data.AltRoute_List[0].AlternateRoute[i],
                                                data.Alt_List[0].Alternate[i],
                                                primArrivalAltFueltime,
                                                primArrivalAltFuelWeight,
                                                nBlockFuel_KG,
                                                parseFloat(data.dbBlockFuelDur_H[0]))
            )
        }
        return(
            <div className="general-route-info">
                <div className="one-route-info-header">
                    <p className="head-icao">ICAO</p>
                    <p className="head-name">NAME</p>
                    <p className="head-wind">WIND</p>
                    <p className="head-fl">FL</p>
                    <p className="head-nm">NM</p>
                    <p className="head-time">TIME</p>
                    <p className="head-fuel">FUEL</p>
                    <p className="head-block">--- BLOCK ---</p>
                </div>
                {altRouteinf}
            </div>


        )
    }

    alternateRouteInfoOneLine(data, alt_list, primAltFuel, primAltFuelweight,nblockFuel, dbblockfuel){
        const lengthwparr = parseInt(data.AltWP_List[0].WayPoint.length)
        const unconvertedTime = data.AltWP_List[0].WayPoint[lengthwparr-1].dbEstCumulatedFlightTime_H
        const time = timeConvert(unconvertedTime)
        const FL = parseInt(data.nInitialAltitude_FT)/100
        let altfuelthis = parseFloat(alt_list.dbAlternateFuelDur_H[0])
        let timefuel = dbblockfuel - primAltFuel + altfuelthis
        let blocktime = timeConvert(timefuel)
        let blockweight = nblockFuel - primAltFuelweight + parseInt(alt_list.nAlternateFuel_KG[0])
        return(
            <div className="one-route-info-line" key={data.sICAO}>
                <p className="mobile-header mobile-header-top">ICAO</p>
                <p className="mobile-header mobile-header-top">NAME</p>
                <p className="mobile-header mobile-header-top">WIND</p>
                <p className="mobile-header mobile-header-top">FL</p>
                <p className="one-route-data-text">{data.sICAO}</p>
                <p className="one-route-data-text">{data.sAirportName}</p>
                <p className="one-route-data-text">{data.nAverageWindDir_TrueDeg}/{data.nAverageWindSpeed_KT}</p>
                <p className="one-route-data-text">{FL}</p>
                <p className="mobile-header">NM</p>
                <p className="mobile-header">TIME</p>
                <p className="mobile-header">FUEL</p>
                <p className="mobile-header">BLOCK</p>
                <p className="one-route-data-text last-data-text">{data.sGroundDistance}</p>
                <p className="one-route-data-text last-data-text">{time}</p>
                <p className="one-route-data-text last-data-text">{data.AltWP_List[0].WayPoint[lengthwparr-1].nEstCumulatedBurnOff_KG}kg</p>
                <p className="one-route-data-text last-data-text">{blocktime} {blockweight}</p>
            </div>
        )
    }

    render () {
        let content = this.alternateRouteInfo(this.props.data)
        return(
            <div className="alt-route-container">
                {content}
            </div>
        )
    }
}

export default alternateRouteInfo;
