export function getCrewListCmd(fltno, sDep,sDest,sSTD) {
    const result = `<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                  <soap:Body>
                    <getCrewList xmlns="http://flightdecksoftware.se/">
                      <sFltNo>${fltno}</sFltNo>
                      <sDep>${sDep}</sDep>
                      <sDest>${sDest}</sDest>
                      <sSTD>${sSTD}</sSTD>
                    </getCrewList>
                  </soap:Body>
                </soap:Envelope>

    `
    return result;

}


export function getFltsCmd(empNo){
    const result =
    `<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                  <soap:Body>
                    <getFlts xmlns="http://flightdecksoftware.se/">
                      <sEmpNo>${empNo}</sEmpNo>
                    </getFlts>
                  </soap:Body>
                </soap:Envelope>
    `
    return result;
}

export function getFltXmlCmd(fltNo, sDep,sDest,sDate, sACReg){
    const result = `<?xml version="1.0" encoding="utf-8"?>
                    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                      <soap:Body>
                        <getFlt_xml xmlns="http://flightdecksoftware.se/">
                          <sFltNo>${fltNo}</sFltNo>
                          <sDep>${sDep}</sDep>
                          <sDest>${sDest}</sDest>
                          <sDate>${sDate}</sDate>
                          <sACReg>${sACReg}</sACReg>
                        </getFlt_xml>
                      </soap:Body>
                  </soap:Envelope>
    `
    return result;
}

export function timeConvert(hours){
     var sign = hours < 0 ? "-" : "";
     var h = Math.floor(Math.abs(hours));
     var min = Math.floor((Math.abs(hours) * 60) % 60);
     return sign + (h < 10 ? "0" : "") + h + ":" + (min < 10 ? "0" : "") + min;
}

// PGD 2019-12-09
export function timeConvert_Ceil(hours){
     var sign = hours < 0 ? "-" : "";
     var h = Math.floor(Math.abs(hours));
     var min = Math.ceil((Math.abs(hours) * 60) % 60);
     return sign + (h < 10 ? "0" : "") + h + ":" + (min < 10 ? "0" : "") + min;
}

// PGD 2019-12-09
export function timeConvert_Round(hours){
     var sign = hours < 0 ? "-" : "";
     var h = Math.floor(Math.abs(hours));
     var min = Math.round((Math.abs(hours) * 60) % 60);
     return sign + (h < 10 ? "0" : "") + h + ":" + (min < 10 ? "0" : "") + min;
}

export function timeConvertSec(hours){
     var sign = hours < 0 ? "-" : "";
     var h = Math.floor(Math.abs(hours));
     var min = Math.floor((Math.abs(hours) * 60) % 60);
     var sec = (Math.floor((Math.abs(hours) * 3600) % 60)/6).toFixed(0);
     return sign + (h < 10 ? "0" : "") + h + ":" + (min < 10 ? "0" : "") + min + "." + sec;
}
