import React from 'react'
import PropTypes from 'prop-types'

class Topinfoline extends React.Component {
    render () {
        let std = this.props.data.dtSTD[0]
        std = std.replace(/[\T,\Z]/g, ' ')
        let stdsplit = std.split(" ")
        const flightDate = stdsplit[0]
        std = stdsplit[1].substring(0,5)
        let sta = this.props.data.dtSTA[0]
        sta = sta.replace(/[\T,\Z]/g, ' ')
        sta = sta.split(" ")[1].substring(0,5)
        let stdsta = String(std)+ " / " + String(sta)
        return(
            <div className="top-info-line-container">
                <div className="one-col-top-info-double">
                    <p>CALLSIGN: </p>
                    <p className="callsign-text">{this.props.data.sCallSign[0]}</p>
                </div>
                <div className="one-col-top-info-single">
                    <p>{this.props.data.sDepIATA[0]}{this.props.data.sDestIATA[0]} </p>
                </div>
                <div className="one-col-top-info-double">
                    <p className="cfn-text">CFN: </p>
                    <p>{this.props.data.sComFltNo[0]}</p>
                </div>
                <div className="one-col-top-info-single">
                    <p>{this.props.data.sACReg[0]}</p>
                </div>
                <div className="one-col-top-info-double">
                    <p>STD/STA:</p>
                    <p>{stdsta}</p>
                </div>
            </div>

        )

    }
}

export default Topinfoline;
