import React from 'react'


class Wxt extends React.Component {

    oneDestWeather(data,key){

        return(
            <div key={key} className="one-w-dest">
                <pre>
                    {data}
                </pre>
            </div>
        )
    }
    render () {
        let allWeatherTxt = []
        let key
        for(let i = 0; i < this.props.data.Weather.length; i++){
            key = "wtx"+i
            allWeatherTxt.push(
                this.oneDestWeather(this.props.data.Weather[i].sWX,key)
            )
        }

        return(
            <div className="wxt-container">

                <div className="wxt-text">
                        {allWeatherTxt}
                </div>
            </div>
        )
    }
}

export default Wxt;
