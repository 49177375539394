import React from 'react';

class Logout extends React.Component {
  logout() {
      // TODO change url for deployment
    window.location.href = 'https://lbserver.norwegian.com/logout6';
  }
  render() {
    return (
      <div className='logout-container'>
        <div className='logout-box' onClick={() => this.logout()}>
          <p>LOGOUT</p>
        </div>
      </div>
    );
  }
}

export default Logout;
