import React from 'react'
import Generalinfo from './../components/Fltdata/Generalinfo'
import {timeConvert} from './../helperfunctions'
import DispatcherRemark from './../components/Fltdata/DispatcherRemark'
class Cabincrewpage extends React.Component {


    render () {
        let std = this.props.data.dtSTD[0]
        std = std.replace(/[\T,\Z]/g, ' ')
        let stdsplit = std.split(" ")
        const flightDate = stdsplit[0]
        std = stdsplit[1]
        let sta = this.props.data.dtSTA[0]
        sta = sta.replace(/[\T,\Z]/g, ' ')
        sta = sta.split(" ")[1]
        let etd = this.props.data.dtEstOutTime[0]
        etd = etd.replace(/[\T,\Z]/g, ' ')
        etd = etd.split(" ")[1]
        const tripTime = timeConvert(this.props.data.dbTripFuelDur_H)



        return(
            <div className="cabincrew-page-container">
                <div className="time-info">
                    <div className="date-and-trip-time">
                        <p>Date:</p>
                        <p>{flightDate}</p>
                        <p>Trip Time:</p>
                        <p>{tripTime}</p>

                    </div>
                    <div className="std-sta-etd">
                        <p>STD:</p>
                        <p>{std}</p>
                        <p>STA:</p>
                        <p>{sta}</p>
                        <p>ETD:</p>
                        <p>{etd}</p>
                    </div>
                </div>
                <Generalinfo data={this.props.data}/>
                <DispatcherRemark data={this.props.data}/>
            </div>

        )
    }
}

export default Cabincrewpage;
