import React from 'react'
import Modal from 'react-modal';



Modal.setAppElement(document.getElementById('root'));

class SelectFP extends React.Component {
    constructor(){
        super()
            this.state = {
                  modalIsOpen: false
                }
                this.openModal = this.openModal.bind(this);
                this.afterOpenModal = this.afterOpenModal.bind(this);
                this.closeModal = this.closeModal.bind(this);
                this.generateFpList = this.generateFpList.bind(this)
    }


      openModal() {
        this.setState({modalIsOpen: true});
      }

      afterOpenModal() {
        this.subtitle.style.color = '#f00';
      }

      closeModal() {
        this.setState({modalIsOpen: false});
      }
      handleClickOnFp(i){
          this.closeModal()
          this.props.handleClick(i)
      }

      generateFpList(data){
          let fplist = []
          for(let i = 0; i < data.length ; i++){
              let key = "flightplan"+i
              fplist.push(
                  <div key={key} className="one-alt-fplist">
                      <p>{data[i].sACreg}</p>
                      <p>{data[i].sDep} -- </p>
                      <p>{data[i].sDest} -- </p>
                      <p>{data[i].sFltNo} -- </p>
                      <p>{data[i].sSTD}</p>
                      <button value={i} onClick={() => this.handleClickOnFp(parseInt(i))}>Select Flight</button>
                  </div>
              )

          }
          return(
              fplist
          )
      }

      render() {
          let fpl
          if(this.props.data){
             fpl  = this.generateFpList(this.props.data)
         } else {
             fpl = <p>No flights found</p>
         }

        return (
          <div className="selectfp-container">
              <div className="selectfp-btn" onClick={this.openModal}>
                  <p>Select FP</p>
              </div>
              <Modal
                  isOpen={this.state.modalIsOpen}
                  onAfterOpen={this.afterOpenModal}
                  onRequestClose={this.closeModal}
                  className="Modal"
                  overlayClassName="Overlay"
                  contentLabel="SELECT FP"
                >

                  <h2 ref={subtitle => this.subtitle = subtitle}>Select flight plan below</h2>
                  <div className="fp-list">
                      {fpl}
                  </div>
                  
                </Modal>
              </div>
        );
      }
    }

export default SelectFP;
