import React from 'react'


class OperationalImpacts extends React.Component {

    oneOpImpactLine(data,key){
        let devReasonChange = data.sDeviationReasonChange[0]
        devReasonChange = devReasonChange.replace(/[-]/g, "DN ")
        devReasonChange = devReasonChange.replace(/[+]/g, "UP ")
        let splitDevReason = devReasonChange.split(" ")
        let spltDevReason1 = splitDevReason[0]
        let spltDevReason2 = splitDevReason[1]
        let fulevalue = data.TypeList[0].AdjustmentType[0].sValue[0]
        fulevalue = fulevalue.replace(/[-]/g, "M ")
        fulevalue = fulevalue.replace(/[+]/g, "P ")
        let splitfulevalue = fulevalue.split(" ")
        let spltfulevalue1 = splitfulevalue[0]
        let spltfulevalue2 = splitfulevalue[1]
        let timeOrTripValue
        let splittimeOrTripValue
        let splttimeOrTripValue1
        let splttimeOrTripValue2
        if(data.TypeList[0].AdjustmentType[1]){

            timeOrTripValue = data.TypeList[0].AdjustmentType[1].sValue[0]
            timeOrTripValue = timeOrTripValue.replace(/[-]/g, "M ")
            timeOrTripValue = timeOrTripValue.replace(/[+]/g, "P ")
            splittimeOrTripValue = timeOrTripValue.split(" ")
            splttimeOrTripValue1 = splittimeOrTripValue[0]
            splttimeOrTripValue2 = splittimeOrTripValue[1]
        }

        let timeOrTrip = "TRIP"
        if(data.sDeviationReason[0] === "TakeoffWeight"){
            timeOrTrip = "TIME"
        }


        return(
            <div key={key} className="operational-impacts-line">
                <p>{data.sDeviationReason[0]}</p>
                <p>{spltDevReason1}</p>
                <p>{spltDevReason2}</p>
                <p>{data.TypeList[0].AdjustmentType[0].sAffectedItem[0]}</p>
                <p>{spltfulevalue1}</p>
                <p>{spltfulevalue2}</p>
                <p>KGS</p>
                <p>{timeOrTrip}</p>
                <p>{splttimeOrTripValue1}</p>
                <p>{splttimeOrTripValue2}</p>


            </div>
        )
    }

    render () {
        let aListData = []

        for( let i = 0; i < this.props.data.Adjustment.length; i++){
            let adjustreason = "adjustreason"+i
            aListData.push(this.oneOpImpactLine(this.props.data.Adjustment[i], adjustreason))
        }





        return(
            <div className="operational-impacts-container">
                <p> Operational Impacts </p>
                <div> </div>
                <div>{aListData}</div>
            </div>
        )
    }
}

export default OperationalImpacts   ;
