import React from 'react'


class CrewMSG extends React.Component {
    render () {
        let crwAlert = <pre>{this.props.data.sCrewAlert[0]}</pre>
        let crwAditionalInfo = (
            <div>
                <pre className="pre-no-pad-no-marg">====================</pre>
                <pre className="pre-no-pad-no-marg">CREW ADDITIONAL INFO </pre>
                <pre className="pre-no-pad-no-marg">====================</pre>
                <pre>{this.props.data.sCrewAdditionalInfo[0]}</pre>
            </div>
            )
        let crwBulletin = <pre>{this.props.data.sCrewBulletin[0]}</pre>
        return(
            <div className="crew-msg-container">
                <div className="crew-msg-box">
                    {crwAlert}
                    {crwAditionalInfo}
                    {crwBulletin}
                </div>
            </div>
        )

    }
}

export default CrewMSG;
