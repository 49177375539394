import React from 'react'


class Fourthrowdata extends React.Component {
    render () {
        return(
            <div className="Fourth-row-container">
                <pre> {this.props.data.sATCFP[0]}</pre>

            </div>
        )
    }
}

export default Fourthrowdata;
