import React from 'react'
import PropTypes from 'prop-types'

class Flightplannew extends React.Component {
    render () {
        return(
            <div className="flight-plan-new-container">
                <div className="flight-plan-new-content">
                    <pre>
                        {this.props.data}
                    </pre>
                </div>
            </div>

        )

    }
}

export default Flightplannew;
