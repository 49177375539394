import React from 'react'


class Notam extends React.Component {
    constructor() {
        super()
        this.state = {
            ICAO: 0,
        }
    }

    onChange(e) {
        this.setState({ICAO: e.target.value})

    }
    render() {
        let notamContent
        let comboboxOptions = []
        let iteratorcounter = 0
        if (this.props.data !== undefined) {
            notamContent = <p>{this.props.data[0].NOTAM[this.state.ICAO].sNOTAM}</p>
            for(let i = 0; i < this.props.data[0].NOTAM.length; i++){
                comboboxOptions.push(
                    <option key={iteratorcounter} value={iteratorcounter} >{this.props.data[0].NOTAM[i].sICAO}</option>
                )
                iteratorcounter += 1
            }
        } else {
            notamContent = <h1> Tom</h1>
        }
        return (
            <div className = "notam-container">
                <div className="combobox-container">
                </div>
                <p className="visible-when-small-screen">For better readability, use horizontal mode!</p>
                <div className = "notam-text">
                    <pre>
                        {notamContent}
                    </pre>
                </div>
            </div>
        )

    }
}

export default Notam;
