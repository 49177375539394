import React, { Component } from 'react';

//import css
import './App.css';
import './style/notam.scss';
import './style/wxt.scss';
import './style/wxg.scss';
import './style/fltdata/flt-data.scss';
import './style/fltdata/fourth-row-data.scss';
import './style/fltdata/operational-impacts.scss';
import './style/fltdata/calculation-time.scss';
import './style/fltdata/dispatcher-remark.scss';
import './style/fltdata/alternate-route.scss';
import './style/select-fp.scss';
import './style/login.scss';
import './style/logout.scss';
import './style/crew-msg.scss';
import './style/flt-plan.scss';
import './style/flightplannew.scss';
import './style/cabin-crew-page.scss';
import './style/topinfoline.scss';
import './style/maint.scss';
import './style/raim.scss';

import Mainapp from './Mainapp';
import Login from './pages/Login';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch
} from 'react-router-dom';

const employeeNr = '12641';

function mainapp({ match }) {
  localStorage.setItem('empno', match.params.usrid);
  return (
    <div>
      <Mainapp empnumber={match.params.usrid} />
    </div>
  );
}

function mainappNoUserid({ match }) {
  let empno = localStorage.getItem('empno');

  return (
    <div>
      <Mainapp empnumber={empno} />
    </div>
  );
}

function login({ match, authenticate }) {
  return (
    <div>
      <Login authenticate={authenticate} />
    </div>
  );
}

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: 'not set',
      employee: '',
      inputBox: ''
    };

    this.changeEmployee = this.changeEmployee.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.isAuth = this.isAuth.bind(this);
    this.componentWillMount = this.componentWillMount.bind(this);
  }

  changeEmployee(empno) {
    this.setState({ employee: this.state.inputBox });
  }

  authenticate() {
    this.setState({ isAuthenticated: true });
    alert('auth now');
  }

  isAuth() {
      // TODO change url for deployment
    fetch('https://lbserver.norwegian.com/isAuth',{
        credentials: 'include'
    })
      .then(res => res.json())
      .then(responseData => {
        this.setState({ isAuthenticated: responseData[0].authBool });
        return responseData[0].authBool;
      })
      .catch(error => console.log(error));
  }
  componentWillMount() {
    this.isAuth();
  }

  render() {
    let isAuthenticated = this.state.isAuthenticated;
    let currentEmp = <p>Current emp: {this.state.employee}</p>;

    return (
      <Router>
        <Switch>
          <Route
            path='/login'
            component={() => <Login authenticate={this.authenticate} />}
            authenticate={this.authenticate}
          />
          <PrivateRoute
            path='/user/:usrid'
            component={mainapp}
            authenticated={this.state.isAuthenticated}
          />
          <PrivateRoute
            path='/*'
            component={mainappNoUserid}
            authenticated={this.state.isAuthenticated}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
