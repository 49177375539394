import React from 'react'

import {timeConvert} from './../helperfunctions'
import {timeConvert_Ceil} from './../helperfunctions'   // PGD 2019-12-09
import {timeConvert_Round} from './../helperfunctions'  // PGD 2019-12-09
import Fourthrowdata from './../components/Fltdata/Fourthrowdata'
import AlternateRouteInfo from './../components/Fltdata/AlternateRouteInfo'
import OperationalImpacts from './../components/Fltdata/OperationalImpacts'
import CalculationTime from './../components/Fltdata/CalculationTime'
import DispatcherRemark from './../components/Fltdata/DispatcherRemark'
import Generalinfo from './../components/Fltdata/Generalinfo'


class Fltdata extends React.Component {


    weightData(data) {
        return(
            <div className="weight-data inner-element">
                <div className="one-weight-row">
                    <p className="first-weight-info">E PL:</p>
                    <p className="second-weight-info">{data.nEstLoad_KG} kg</p>
                </div>
                <div className="one-weight-row ">
                    <p className="first-weight-info yellow-text">E ZFM:</p>
                    <p className="second-weight-info yellow-text">{data.nEstZFW_KG} kg</p>
                </div>
                <div className="one-weight-row">
                    <p className="first-weight-info">E TO F:</p>
                    <p className="second-weight-info">{data.nTakeOffFuel_KG} kg</p>
                </div>
                <div className="one-weight-row">
                    <p className="first-weight-info yellow-text">E TOM:</p>
                    <p className="second-weight-info yellow-text">{data.nEstTOW_KG} kg</p>
                </div>
                <div className="one-weight-row">
                    <p className="first-weight-info">E TRIP:</p>
                    <p className="second-weight-info">{data.nTripFuel_KG} kg</p>
                </div>
                <div className="one-weight-row">
                    <p className="first-weight-info">E LAND:</p>
                    <p className="second-weight-info">{data.nEstLW_KG} kg</p>
                </div>
                <div className="one-weight-row">
                    <p className="first-weight-info">OPT FUEL:</p>
                    <p className="second-weight-info">{data.nBlockFuel_KG} kg</p>
                </div>
                <div className="one-weight-row">
                    <p className="first-weight-info">GAIN/LOSS:</p>
                    <p className="second-weight-info">{data.sGainLoss[0]}</p>
                </div>

                <div className="one-weight-row row-with-margin-top">
                    <p className="first-weight-info ">OPT:</p>
                    <p className="second-weight-info ">{data.sRouteInfoOptimization[0]}</p>
                </div>
            </div>

        )
    }

    weightAndTimeData(data){
        //const tripTime = timeConvert(data.dbTripFuelDur_H)
        const tripTime = timeConvert_Round(data.dbTripFuelDur_H)   // PGD 2019-12-09
        const cfTime = timeConvert(data.dbContingencyDur_H)
        const taxiTime = timeConvert(data.dbTaxiFuelDur_H)
        const fresTime = timeConvert(data.dbFinalReserveDur_H)

        const primArrival = data.Alt_List[0].Alternate.find(function(element){
            return element.sADFunction =="PrimaryArrivalAlternateAirport"
        })
        //const alt1Time = timeConvert(primArrival.dbAlternateFuelDur_H)
        const alt1Time = timeConvert_Round(primArrival.dbAlternateFuelDur_H) // PGD 2019-12-09
        const rampTime = timeConvert(data.dbBlockFuelDur_H)
        const minreqTime = timeConvert(data.dbTakeOffFuelDur_H)

        let etops

        if(parseInt(data.nETOPSFuel_KG) > 0){
            const etopsTime = timeConvert_Round(data.dbETOPSFuelDur_H)
            etops = <div className="weight-and-time-row">
                        <p className="first-weight-and-time">ETOPS:</p>
                        <p className="second-weight-and-time">{etopsTime}</p>
                        <p className="Third-weight-and-time">{data.nETOPSFuel_KG} kg</p>
                    </div>
        }

        //Present ADDFuel fuel if specified   PGD 2019-12-04
        let opn

        if(parseInt(data.nADDFuel_KG) > 0){
            const opnTime = timeConvert_Round(data.dbADDFuelDur_H)
            etops = <div className="weight-and-time-row">
                        <p className="first-weight-and-time">{data.sADDFuelReason}:</p>
                        <p className="second-weight-and-time">{opnTime}</p>
                        <p className="Third-weight-and-time">{data.nADDFuel_KG} kg</p>
                    </div>
        }

        return(
            <div className="weight-and-time-data inner-element">
                <div className="weight-and-time-row">
                    <p className="first-weight-and-time">TRIP:</p>
                    <p className="second-weight-and-time">{tripTime}</p>
                    <p className="Third-weight-and-time">{data.nTripFuel_KG} kg</p>
                </div>
                <div className="weight-and-time-row">
                    <p className="first-weight-and-time">CF:</p>
                    <p className="second-weight-and-time">{cfTime}</p>
                    <p className="Third-weight-and-time">{data.nContingencyFuel_KG} kg</p>
                </div>
                <div className="weight-and-time-row">
                    <p className="first-weight-and-time">TAXI:</p>
                    <p className="second-weight-and-time">{taxiTime}</p>
                    <p className="Third-weight-and-time">{data.nTaxiFuel_KG} kg</p>
                </div>
                <div className="weight-and-time-row">
                    <p className="first-weight-and-time">FRES:</p>
                    <p className="second-weight-and-time">{fresTime}</p>
                    <p className="Third-weight-and-time">{data.nFinalReserve_KG} kg</p>
                </div>
                <div className="weight-and-time-row">
                    <p className="first-weight-and-time">ALT1:</p>
                    <p className="second-weight-and-time">{alt1Time}</p>
                    <p className="Third-weight-and-time">{primArrival.nAlternateFuel_KG} kg</p>
                </div>

                {etops}

                {opn}

                <div className="weight-and-time-row row-with-margin-top">
                    <p className="first-weight-and-time">T/OFF:</p>
                    <p className="second-weight-and-time">{minreqTime}</p>
                    <p className="Third-weight-and-time">{data.nTakeOffFuel_KG} kg</p>
                </div>
                <div className="weight-and-time-row row-with-margin-top">
                    <p className="first-weight-and-time yellow-text">BLOCK:</p>
                    <p className="second-weight-and-time yellow-text">{rampTime}</p>
                    <p className="Third-weight-and-time yellow-text">{data.nBlockFuel_KG} kg</p>
                </div>
                <div className="weight-and-time-row">
                    <p className="first-weight-and-time">AV FFLOW:</p>
                    <p className="second-weight-and-time"></p>
                    <p className="Third-weight-and-time">{data.nAverageFF_KGH} kg/h</p>
                </div>
            </div>
        )
    }

    thirdcolumnsecondsection(data){
        const fl = data.nInitialAltitude_FT/100
        const ci = data.sCruiseProcedure[0].replace(/\D/g,'');


        let tocwv = data.sTOC_Wind
        //let tocwv = String(toc.sWindDir[0].replace(/\D/g,'')) + '/' + String(toc.sWindSpeed[0].replace(/\D/g,''))

        let avgwc = data.nAverageWindComponent_KT[0]
        let avgwcString
        if(avgwc.includes("-")){
            avgwcString = "HEAD"
            avgwc = avgwc.replace(/\D/g,'')
        } else {
            avgwcString = "TAIL"
        }

        let perf = parseFloat(data.dbPerfFactor[0]).toFixed(1);

        return(
            <div className="third-column-second-section">
                <div className="tcss-row">
                    <p className="tcss-first-col yellow-text">FL:</p>
                    <p className="tcss-second-col span-grid-row">{fl}</p>
                </div>
                <div className="tcss-row">
                    <p className="tcss-first-col">CI:</p>
                    <p className="tcss-second-col span-grid-row">{ci}</p>
                </div>
                <div className="tcss-row">
                    <p className="tcss-first-col yellow-text">TOC ISA DEV:</p>
                    <p className="tcss-second-col span-grid-row">{data.sTOC_ISA}</p>
                </div>
                <div className="tcss-row">
                    <p className="tcss-first-col yellow-text">TOC W/V:</p>
                    <p className="tcss-second-col span-grid-row">{tocwv}</p>
                </div>
                <div className="tcss-row">
                    <p className="tcss-first-col yellow-text">TOC OAT:</p>
                    <p className="tcss-second-col span-grid-row">{data.sTOC_OAT}</p>
                </div>
                <div className="tcss-row">
                    <p className="tcss-first-col">FP NM:</p>
                    <p className="tcss-second-col span-grid-row">{data.sGroundDistance[0].replace(/\D/g,'')}</p>
                </div>
                <div className="tcss-row">
                    <p className="tcss-first-col">GC NM:</p>
                    <p className="tcss-second-col span-grid-row">{data.sGreatCircleDistance[0].replace(/\D/g,'')}</p>
                </div>
                <div className="tcss-row">
                    <p className="tcss-first-col">AIR NM:</p>
                    <p className="tcss-second-col span-grid-row">{data.sAirDistance[0].replace(/\D/g,'')}</p>
                </div>
                <div className="tcss-row">
                    <p className="tcss-first-col">AVG WC:</p>
                    <p className="tcss-second-col span-grid-row">{avgwc} {avgwcString}</p>
                </div>
                <div className="tcss-row">
                    <p className="tcss-first-col">FUEL BIAS:</p>
                    <p className="tcss-second-col span-grid-row">{perf}%</p>
                </div>

            </div>
        )
    }

    // PGD 2019-12-04
    handlingInfo(data){

        return(
        <div className="handling-info">
            <div className="handling-data">
                <pre>
                    {data}
                </pre>
            </div>
        </div>
        )
    }

    decentWinds(data){

        return(
        <div className="decent-winds-info">
            <div className="decent-winds-data">
                <pre>
                    {data}
                </pre>
            </div>
        </div>
        )
    }



    render () {

        let generaldataAndCrew
        let weightdata
        let tempandotherdata
        let thirdColSecSection
        let handlingInfo        // PGD 2019-12-04
        let decentWinds


        if(this.props.data !== undefined ){
            generaldataAndCrew = <Generalinfo data={this.props.data} />
            weightdata = this.weightData(this.props.data)
            tempandotherdata = this.weightAndTimeData(this.props.data)
            thirdColSecSection = this.thirdcolumnsecondsection(this.props.data)
            handlingInfo= this.handlingInfo(this.props.data.sHandlingInfo)      // PGD 2019-12-04
            decentWinds= this.decentWinds(this.props.data.senRouteWeatherConditions[0])


        }


        return(
            <div className="flt-data-container">
                {generaldataAndCrew}
                <div className="weight-and-weather-info">
                    {weightdata}
                    {tempandotherdata}
                    {thirdColSecSection}
                </div>
                <div>
                    {handlingInfo}
                </div>
                <div>
                    {decentWinds}
                </div>
                <AlternateRouteInfo data={this.props.data}/>
                <Fourthrowdata data={this.props.data}/>
                <OperationalImpacts data={this.props.data.Adjustment_List[0]}/>
                <CalculationTime data ={this.props.data.dtFPTimestamp[0]} fpver={this.props.data.sFPVer[0]}/>
                <DispatcherRemark data={this.props.data}/>

            </div>
        )

    }
}

export default Fltdata;
