import React from 'react'


class CalculationTime extends React.Component {
    render () {
        let time =  this.props.data.replace(/T|Z/g, " ").substring(0,19);
        
        return(
            <div className="calculation-time-container">
                <p>
                    CALCULATION TIME:
                </p>
                <p>
                    {time}  {this.props.fpver}
                </p>
            </div>

        )
    }
}

export default CalculationTime;
