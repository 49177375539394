import React from 'react'


class Generalinfo extends React.Component {

    crewMember(data){
        let fname = data.sFirstName[0].toUpperCase()
        let lname = data.sLastName[0].toUpperCase()
        return(
                <div className = "crew-member-info" key={data.nEmpNo} >
                    <p className="crewinfo-crewCat">{data.sCrewCat}</p>
                    <p className="crewinfo-nEmpNo">{data.nEmpNo}</p>
                    <p className="crewinfo-sFirstName">{fname}</p>
                    <p className="crewinfo-sLastName">{lname}</p>
                </div>
        )
    }

    generalData(data) {

        let std = this.props.data.dtSTD[0]
        std = std.replace(/[\T,\Z]/g, ' ')
        let stdsplit = std.split(" ")
        const flightDate = stdsplit[0]

        const destsination = data.Airport_List[0].AirportData.find(function(element){
            return element.sAirportFunction == "ArrivalAirport"
        })

        const alt1 = data.AltRoute_List[0].AlternateRoute.find(function(element){
            return element.sAirportFunction == "PrimaryArrivalAlternateAirport"
        })

        const alt2 = data.AltRoute_List[0].AlternateRoute.find(function(element){
            return element.sAirportFunction == "secondAlternateAirport"
        })


        let altRoute1sICAO
        let altRoute1sIATA
        let altRoute1sAirport

        if(alt1){
            altRoute1sICAO= alt1.sICAO
            altRoute1sIATA = alt1.sIATA
            altRoute1sAirport = alt1.sAirportName
        }
        let altRoute2sICAO
        let altRoute2sIATA
        let altRoute2sAirport
        let slash

        if(alt2){
            altRoute2sICAO= alt2.sICAO
            altRoute2sIATA = alt2.sIATA
            altRoute2sAirport = alt2.sAirportName
            slash = '/'
        }


        return(
            <div className="general-flt-info inner-element">
                    <p className="general-date1">Date:</p>
                    <p className="general-date2">{flightDate}</p>

                    <p className="general-flightnr1" >FLIGHTNR:</p>
                    <p className="general-flightnr2">{data.sFlightIdentifier[0]}</p>

                    <p className="general-callsign1">CALLSIGN:</p>
                    <p className="general-callsign2">{data.sCallSign}</p>

                    <p className="general-from1">FROM:</p>
                    <p className="general-from12">{data.Airport_List[0].AirportData[0].sICAO}/{data.Airport_List[0].AirportData[0].sIATA}</p>
                    <p className="general-from2">{data.Airport_List[0].AirportData[0].sAirportName}</p>
                    <p className="general-from3"></p>

                    <p className="general-to1">TO:</p>
                    <p className="general-to12">{destsination.sICAO}/{destsination.sIATA}</p>
                    <p className="general-to2">{destsination.sAirportName}</p>
                    <p className="general-to3"></p>


                    <p className="general-alt1-1">ALT1:</p>
                    <p className="general-alt1-2">{altRoute1sICAO}/{altRoute1sIATA}</p>
                    <p className="general-alt1-3">{altRoute1sAirport}</p>
                    <p className="general-alt1-4"></p>

                    <p className="general-alt2-1">ALT2:</p>
                    <p className="general-alt2-2">{altRoute2sICAO}{slash}{altRoute2sIATA}</p>
                    <p className="general-alt2-3">{altRoute2sAirport}</p>
                    <p className="general-alt2-4"></p>

                    <p className="general-pax1">PAX:</p>
                    <p className="general-pax2">{data.sPax[0].split("Pax figures:")}</p>

            </div>
        )

    }


    render () {
        let crewcontent = []
        let generaldata

        for(let i=0; i < this.props.data.Crew_List[0].Crew.length; i++){
            crewcontent.push(
                this.crewMember(this.props.data.Crew_List[0].Crew[i])
            )
        }
        generaldata = this.generalData( this.props.data)
        return(
            <div>
                <div className="general-and-crew-container">
                    {generaldata}
                    <div className="crew-info">
                        {crewcontent}
                    </div>
                </div>
            </div>
        )
    }
}

export default Generalinfo;
