import React from 'react'


import './../style/contentwindow.scss'

class ContentWindow extends React.Component {
    constructor(){
        super()
    }
    render () {
        return (
            <div className="main-container">
                <h1>Content Window</h1>
            </div>

        )

    }
}

export default ContentWindow ;
